import { motion } from "framer-motion";
import { cn } from "~/lib/utils";

export const Loading = () => {
  return (
    <motion.div
      className={cn(
        "fixed z-[99999] flex h-screen w-screen items-center justify-center bg-white",
      )}
      initial={{
        opacity: 0,
        filter: "blur(10px)",
        transform: "translate(-80px, 0px)",
      }}
      animate={{
        opacity: 1,
        filter: "blur(0px)",
        transform: "translate(0px, 0px)",
      }}
      exit={{
        opacity: 0,
        filter: "blur(10px)",
        transform: "translate(80px, 0px)",
      }}
      transition={{ duration: 0.3, delay: 0.5 }}
      id="loading"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="188"
        height="56"
        viewBox="0 0 188 56"
        fill="none"
        className={cn("h-[26px] w-[87px]", "md:h-[56px] md:w-[188px]")}
      >
        <path
          d="M9.03419 51.8052C6.28695 50.5901 4.14727 48.9096 2.53591 46.8154C0.950967 44.7213 0.105663 42.3686 0 39.7574H9.40401C9.5625 41.5671 10.4606 43.0925 12.072 44.3076C13.6834 45.5228 15.7174 46.1432 18.174 46.1432C20.6307 46.1432 22.6911 45.652 24.1176 44.6954C25.5176 43.7388 26.2308 42.4979 26.2308 40.9984C26.2308 39.3954 25.4384 38.1803 23.8798 37.4047C22.3213 36.6291 19.8382 35.7759 16.4306 34.8193C13.1286 33.9403 10.4606 33.0871 8.37379 32.2339C6.31336 31.4066 4.51709 30.1398 2.98498 28.4076C1.47928 26.7013 0.713224 24.4261 0.713224 21.6339C0.713224 19.3588 1.40004 17.2647 2.77365 15.3515C4.14727 13.4642 6.12845 11.9646 8.7172 10.8788C11.2795 9.79293 14.2645 9.25 17.5929 9.25C22.5855 9.25 26.6007 10.491 29.6649 12.9471C32.7291 15.429 34.3405 18.79 34.5518 23.03H25.4648C25.3063 21.1169 24.5138 19.5656 23.0874 18.4281C21.6609 17.2905 19.7061 16.7217 17.2759 16.7217C14.8457 16.7217 13.0494 17.1612 11.7814 18.0403C10.5135 18.9193 9.87949 20.0827 9.87949 21.5564C9.87949 22.6939 10.3021 23.6505 11.1474 24.452C11.9927 25.2276 13.023 25.8481 14.2645 26.2876C15.4796 26.7271 17.3023 27.2959 19.6797 27.9681C22.876 28.7954 25.4912 29.6486 27.5252 30.5018C29.5592 31.3549 31.3291 32.6218 32.8348 34.2764C34.3141 35.931 35.0801 38.1545 35.1329 40.8949C35.1329 43.351 34.4461 45.5228 33.0725 47.4359C31.6989 49.3491 29.7441 50.8745 27.2082 51.9603C24.6723 53.0462 21.7137 53.5891 18.3325 53.5891C14.9513 53.5891 11.7814 52.9686 9.0606 51.7535L9.03419 51.8052Z"
          fill="#111010"
        />
        <path
          d="M76.0093 9.9739V15.0154C73.4734 12.7661 70.4092 11.0856 67.028 10.129C65.1261 9.56024 63.0656 9.25 60.7939 9.25C60.0806 9.25 59.3938 9.30171 58.7334 9.35342C58.6014 9.35342 58.4693 9.35341 58.3372 9.37927C58.1259 9.37927 57.9146 9.43098 57.7032 9.45683C46.6615 10.9305 38.1556 20.212 38.1556 31.4066C38.1556 42.6013 46.0275 51.1589 56.4353 53.1496C56.6994 53.2013 56.99 53.253 57.2542 53.3047C57.307 53.3047 57.3334 53.3047 57.3862 53.3047C58.4429 53.4598 59.5259 53.5633 60.6618 53.5633C61.1109 53.5633 61.5599 53.5633 61.9826 53.5374C67.3714 53.2789 72.2847 51.1589 76.0358 47.8237V52.8652H85.202V9.92219H76.0358L76.0093 9.9739ZM61.5071 45.9106C53.5031 45.9106 47.0049 39.4471 47.0049 31.4583C47.0049 23.4695 53.5031 17.0061 61.5071 17.0061C69.5111 17.0061 76.0093 23.4695 76.0093 31.4583C76.0093 39.4471 69.5111 45.9106 61.5071 45.9106Z"
          fill="#111010"
        />
        <path
          d="M110.521 52.221C108.619 53.1 105.977 53.6429 103.045 53.6429C99.1092 53.6429 95.7544 52.4278 93.6939 49.2995C92.9543 48.1878 92.3732 46.8175 92.0033 45.1887C92.0033 45.0853 91.9505 44.9561 91.9241 44.8268C91.8184 44.2839 91.7128 43.6892 91.6599 43.0687C91.6599 42.9653 91.6599 42.8878 91.6335 42.7844C91.6335 42.6292 91.6071 42.4741 91.6071 42.319C91.6071 42.2414 91.6071 42.1897 91.6071 42.1121C91.6071 41.8019 91.5543 41.4658 91.5543 41.1297C91.5543 41.078 91.5543 41.0521 91.5543 41.0004C91.5543 40.6902 91.5543 40.3541 91.5543 40.018C91.5543 40.018 91.5543 39.9921 91.5543 39.9663C91.5543 39.6819 91.5543 39.3717 91.5543 39.0614C91.6071 32.7531 92.9543 24.816 94.9883 17.4219H90.392C90.392 15.5345 91.0259 12.6648 92.426 9.95014H97.3921C98.4224 7.13208 99.5582 4.54671 100.721 2.375H110.442C109.992 3.12476 108.804 6.20135 108.038 8.19208C107.774 8.86428 107.536 9.48477 107.377 9.95014H114.827C113.426 12.6648 112.793 15.5345 112.793 17.4219H104.973C103.283 23.2389 101.988 29.2112 101.539 34.0975C101.328 36.5019 101.302 38.6219 101.539 40.3541C101.539 40.5092 101.592 40.6385 101.619 40.7936C101.671 41.1039 101.777 41.3883 101.883 41.6726C101.936 41.8019 101.988 41.957 102.068 42.0863C102.358 42.6551 102.755 43.1463 103.23 43.56C103.758 43.9995 104.366 44.3614 105.053 44.62C105.475 44.7751 105.924 44.9044 106.4 44.9819C108.117 45.2663 110.072 45.0078 111.974 44.1029L110.547 52.1434L110.521 52.221Z"
          fill="#111010"
        />
        <path
          d="M161.706 9.28125C149.026 9.28125 138.988 19.3642 138.988 31.5154C138.988 43.6667 149.211 53.6462 161.706 53.6462C174.201 53.6462 184.608 43.6408 184.608 31.5154C184.608 19.3901 174.306 9.28125 161.706 9.28125ZM161.812 45.373C154.019 45.373 147.917 39.0389 147.917 31.593C147.917 24.1471 153.834 17.632 161.812 17.632C169.789 17.632 175.706 23.8627 175.706 31.412C175.706 38.9613 169.684 45.373 161.812 45.373Z"
          fill="#111010"
        />
        <path
          d="M135.841 52.221C133.939 53.1 131.297 53.6429 128.365 53.6429C124.429 53.6429 121.074 52.4278 119.014 49.2995C118.274 48.1878 117.693 46.8175 117.323 45.1887C117.323 45.0853 117.271 44.9561 117.244 44.8268C117.138 44.2839 117.033 43.6892 116.98 43.0687C116.98 42.9653 116.98 42.8878 116.954 42.7844C116.954 42.6292 116.927 42.4741 116.927 42.319C116.927 42.2414 116.927 42.1897 116.927 42.1121C116.927 41.8019 116.874 41.4658 116.874 41.1297C116.874 41.078 116.874 41.0521 116.874 41.0004C116.874 40.6902 116.874 40.3541 116.874 40.018C116.874 40.018 116.874 39.9921 116.874 39.9663C116.874 39.6819 116.874 39.3717 116.874 39.0614C116.927 32.7531 118.274 24.816 120.308 17.4219H115.712C115.712 15.5345 116.346 12.6648 117.746 9.95014H122.712C123.742 7.13208 124.878 4.54671 126.041 2.375H135.762C135.312 3.12476 134.124 6.20135 133.358 8.19208C133.094 8.86428 132.856 9.48477 132.697 9.95014H140.147C138.747 12.6648 138.113 15.5345 138.113 17.4219H130.293C128.603 23.2389 127.308 29.2112 126.859 34.0975C126.648 36.5019 126.622 38.6219 126.859 40.3541C126.859 40.5092 126.912 40.6385 126.939 40.7936C126.991 41.1039 127.097 41.3883 127.203 41.6726C127.256 41.8019 127.308 41.957 127.388 42.0863C127.678 42.6551 128.075 43.1463 128.55 43.56C129.078 43.9995 129.686 44.3614 130.373 44.62C130.795 44.7751 131.244 44.9044 131.72 44.9819C133.437 45.2663 135.392 45.0078 137.294 44.1029L135.867 52.1434L135.841 52.221Z"
          fill="#111010"
        />
      </svg>
    </motion.div>
  );
};
